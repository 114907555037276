* {
  padding: 0;
  box-sizing: border-box;
  font-style: italic;
}

html, body, #root{
  height: 100%;
  width: 100%;
  text-align: center;
  font-style: italic;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0;
}

.content
{
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 75px);
  overflow: auto;
  width: 100%;
  background-color: rgb(0, 0, 0);
}

/*...........................about css start........................... */

.AboutScreen{
  height: auto;
  width: 100%;
}

.about_us
{
  height: 55vh;
  width: 100%;
  color: whitesmoke;
  flex-direction: column;
  background-image: url("../src/Assets/CourseImages/Basic_bartender.jpg");
  object-fit: contain;
  background-color: transparent;
  background-size: cover;
  margin-top: -2%;
}

.AboutScreenHeader
{
  width: 35%;
  font-size: 30px;
  padding-top: 5%;
  text-align: start;
  margin-left: 10%;
}

.about_us_p
{
  font-size: 22px;
  float: left;
  /* height: 38vh; */
  width: 60%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-style: italic;
  margin-left: 10%;
}

.why_us
{
  padding: 10px;
  width: 100%;
  height: auto;
  object-fit: contain;
  display: flex;
  text-align: -webkit-auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  /* margin-bottom: -2%; */
}

.whyUsHeader
{
  width: 35%;
  font-size: 30px;
  margin-left: 10%;
  margin-bottom: 1%;
}

.why_usImg{
  float: left;
  height: 50vh;
  width: 40%;
  display: block;
}
.why_usImg img
{
  width: 80%;
  height: 35vh;
  margin-top: 6%;
  border-radius: 12px 12px 12px 12px;
}

.why_usContent
{
  float: right;
  display: block;
  width: 60%;
}

#why_usContent_p{
  font-size: 20px;
    float: left;
    height: 20vh;
    width: 86%;
    text-align: left;
    font-family: sans-serif;
    font-style: italic;
    margin-left: 10%;
    font-weight: bold;
}
.whyus_logos img
{
  width: 8.7%;
  height: 12vh;
  margin-left: 1%;
}

@media screen and (min-width: 1000px) and (max-width: 1300px)
{
  .about_us
  {
    height: 50vh;
    background-size: cover;
  }
  .about_us_p {
    margin-left: 8%;
    font-size: 20px;
  }
  .AboutScreen h1 {
    width: 35%;
    font-size: 35px;
    text-align: end;
  }
  .AboutScreenHeader{
    margin-left: 8%;
  }
  .why_us
  {
    height: 45vh;
  }
  #why_usContent_p
  {
    font-size: 18px;
  }

}
@media screen and (min-width: 900px) and (max-width: 1000px)
{
  .AboutScreenHeader{
    margin-left: 10%;
    font-size: 21px;
    text-align: start;
  }
  .about_us_p {
    font-size: 15px;
  }
  .about_us
  {
    height: 30vh;
    background-size: cover;
  }
  .whyUsHeader
  {
    font-size: 21px;
  }
  #why_usContent_p {
    font-size: 15px;
  }
  .why_usImg img {
    width: 80%;
    height: 16vh;
    object-fit: contain;
  }
  .why_us {
    height: 23vh;
  }
  
}
  @media screen and (min-width: 700px) and (max-width: 900px)
  {
  .about_us {
    height: 32vh;
    background-size: cover;
  }
  #AboutScreen h1 {
    font-size: 30px;
  }
  .about_us_p 
  {
    font-size: 16px;
    /* margin-left: 5%; */
  }
  .AboutScreenHeader {
    font-size: 25px;
  }
  .whyUsHeader {
    font-size: 25px;
  }
  #why_usContent_p {
    font-size: 13px;
  }
  .why_usImg img {
    height: 18vh;
    object-fit: contain;
  }
  .why_us {
    height: 23vh;
  }
  .whyus_logos img {
    height: 7vh;
    object-fit: cover;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px)
{
  .AboutScreenHeader {
    width: 43%;
    font-size: 25px;
  }
  .about_us {
    height: 45vh;
    margin-top: -4%;
  }

  .about_us_p {
    font-size: 16px;
    float: left;
  }
  .whyUsHeader {
    font-size: 25px;
  }

  #why_usContent_p {
    font-size: 16px;
  }

  .why_usImg
  {
    height: 42vh;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px)
{
  .AboutScreenHeader {
    width: 43%;
    font-size: 22px;
  }
  .about_us
  {
    height: 30vh;
    background-size: cover;
    margin-top: -3%;
  }
  .about_us_p {
    font-size: 12px;
    float: left;
  }
  #why_usContent_p {
    font-size: 12px;
  }
  .why_us {
    flex-direction: row;
    height: 26vh;
  }

  .why_usImg img {
    width: 93%;
    height: 25vh;
    object-fit: contain;
  }
  .whyus_logos{
    flex-direction: row;
  }
  .whyus_logos img{
    height: 10vh;
    object-fit: cover;
  }
  .whyUsHeader {
    font-size: 22px;
  }
  
}

@media screen and (min-width: 400px) and (max-width: 500px)
{
  .about_us 
  {
    height: 25vh;
    background-size: cover;
    margin-top: -4%;
  }

  .AboutScreenHeader
  {
    font-size: 20px;
   
  }
  .about_us_p 
  {
    font-size: 12px;
  }
  .why_us {
    height: 23vh;
    flex-direction: row;
  }  
  .whyUsHeader
  {
    font-size: 20px;
  }
  .whyus_logos{
    flex-direction: row;
  }
  #why_usContent_p {
    font-size: 10px;
  }
  .whyus_logos img {
    width: 11.7%;
    height: 7vh;
    margin-left: 3%;
    object-fit: cover;
  }
  .why_usImg img {
    height: 15vh;
    object-fit: contain;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  .about_us 
  {
    height: 27vh;
    background-size: cover;
    margin-top: -3%;
  }
  .AboutScreen h1
  {
    font-size: 17px;
  }
  .about_us_p 
  {
    font-size: 12px;
  }
  #why_usContent_p {
    font-size: 10px;
  }

  .why_usImg img {
    height: 15vh;
    object-fit: contain;
  }
  .why_us {
    height: 23vh;
    flex-direction: row;
  }  
  .whyus_logos{
    flex-direction: row;
  }
  .whyus_logos img {
    width: 11.7%;
    height: 7vh;
    margin-left: 3%;
    object-fit: cover;
}
}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  .about_us 
  {
    height: 22vh;
    background-size: cover;
  }
  .AboutScreen h1 {
    font-size: 13px;
  }
  .about_us_p {
    font-size: 9px;
    float: left;
    text-align: left;
    margin-left: 9%;
  }
  #why_usContent_p {
    font-size: 7px;
  }

  .why_usImg img {
    height: 15vh;
    object-fit: contain;
  }
  .why_us {
    height: 18vh;
    flex-direction: row;

  }  
  .whyus_logos{
    flex-direction: row;
  }
  .whyus_logos img {
    width: 13%;
    height: 7vh;
    margin-left: 2%;
    object-fit: contain;
}
}
/*.......... ............About css end.............................. */


/*.....................CoursesScreen.......................*/

#CoursesScreen
{
  height: auto;
  width: 100%;
  background-color: white;
  flex-direction: column;
}

.courseTitle
{
  margin-top: 1%;
  /* color: white; */
  font-family: serif;
  font-style: italic;
}
.courseCards1
{
  padding: 10px;
  width: 80%;
  height: auto;
  margin: 0% 0% 2% 10%;
  object-fit: contain;
  display: flex;
  /* color: white; */
  text-align: -webkit-auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.courseCards1Img
{
  float: left;
  height: 50vh;
  width: 40%;
  display: block;
}

.courseCards1Img img{
  width: 100%;
  height: 50vh;
  margin-top: 5%;
  border-radius: 12px 12px 12px 12px;
}

.courseCards1Content
{
  float: right;
  display: block;
  width: 60%;
  margin-left: 4%;
}

.courseCards1 ul{
  font-size: 18px;
  font-family: initial;
  font-style: italic;
  font-weight: bolder;
  list-style: none;
  margin: 1% 1% 1% 0%;
  float: left;
 }

.courseCards1 li::before 
{ 
  content: "";
  border-color: transparent white;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  left: -1em;
  top: 0.9em;
  position: relative;
}

.courseCardsDes{
  font-size: 18px;
  font-family: initial;
  font-style: italic;
  font-weight: bolder;
  width: 95%;
  text-align: left;
  margin: 1% 1% 1% 5%;
}

.courseSubHeader
{
  font-size: 15px;
  font-family: initial;
  font-style: italic;
  font-weight: bolder;
  width: 95%;
  margin: 2% 0% 0% 4.5%;
  text-align: left;
}

.coursesHeader
{
  text-align: center;
  font-size: 30px;
  margin-right: 23%;
  font-style: italic;
  font-family: serif;
  font-weight: bolder;
  width: 100%;
}

.coursesTeach
{
  margin: 2% 1% 1% 0%;
  font-style: italic;
}

.coursesDuration
{
  font-style: italic;
  margin: 1%;
  text-align: center;
}

.coursesCards{
  padding: 20px;
  border-radius: 5px;
  background: #F7F5F7;
  margin: auto;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60% ;
  width: 50%;
  height: 22vh;
}

.cards{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 2px solid lightgrey;
}
.cards p
{
  margin: 1% 1% 1% 10%;
}

.cards h3
{
  font-size: 14px;
}
.cards i
{
  margin: 1% 1% 1% 1%;
  color: rgb(22, 22, 75);
}
.CourseContent
{
  width: 100%;
  height: auto;
}


@media screen and (min-width: 850px) and (max-width: 1000px)
{
  .courseCards1 {
    margin: 0% 0% 5% 2%;
    width: 90%;
    
  }
  .courseCards1Img img{
    height: 40vh;
  }
  .coursesCards
  {
    width: 72%;
    margin-top: -10%;
    height: 15vh ;
  
  }
}

@media screen and (min-width: 700px) and (max-width: 850px)
{
  .courseCards1 {
    margin: 0% 0% 5% 2%;
    flex-direction: column;
  }
  .courseCards1Content{
    width: 100%;
  }
  .courseCards1Img {
    height: 40vh;
    margin-left: 10%;
  }
  .courseCards1Img img{
    width: 70vw;
    height: 38vh;
    
  }
  .coursesCards
  {
    width: 80%;
    margin-top: -6%;
    height: 13vh;
  }
  .cards {
    width: 30%;
  }
  .cards h3 {
    font-size: 12px;
}
.cards p {
  /* margin: 1% 1% 1% 10%; */
  font-size: 13px;
}

}

@media screen and (min-width: 500px) and (max-width: 700px)
{
  .courseCards1 {
    margin: 0% 0% 5% 2%;
    flex-direction: column;
  }
  .courseCards1Content{
    width: 100%;
  }
  .coursesHeader{
    font-size: 28px;
  }

  .courseCards1Img {
    height: 40vh;
    margin-left: 10%;
  }

  .courseCards1Img img{
    width: 75vw;
    height: 40vh;
    margin-top: 0;
  }
  .coursesDuration{
    width: 35vw;
  }
  .coursesCards
  {
    width: 90%;
    margin-top: -6%;
  }
  .cards {
    width: 32%;
  }
  .cards h3 {
    font-size: 9px;
}
.cards p {
  font-size: 10px;
}
}
@media screen and (min-width: 400px) and (max-width: 500px)
{
  .courseCards1 {
    flex-direction: column;
  }
  .courseTitle{
    font-size: 30px;
  }
  .coursesHeader{
    font-size: 18px;
  }
  .coursesTeach {
    font-size: 18px;
}
  .courseCards1Img{
    height: 34vh;
    width: 35vh;
  }
  .courseCards1Img img
  {
    width: 70vw;
    height: 28vh;
    margin-top: 10%;
  }
  .coursesDuration{
    margin: 1%;
    width: 65vw;
  }
  .courseCards1Content{
    width: 90%;
    margin-left: 0%;
  }
  .courseCardsDes{
    font-size: 16px;
    margin-left: 2%;
    margin-bottom: 5%;
  }
  .courseCards1 ul{
    font-size: 15px;
  }
  .coursesCards
  {
    width: 90%;
    margin-top: -3%;
    height: 15vh;
  }
  .cards {
    width: 32%;
  }
  .cards h3 {
    font-size: 9px;
}
.cards p {
  font-size: 10px;
}
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  .courseCards1 {
    flex-direction: column;
  }
  .courseTitle{
    font-size: 30px;
  }
  .coursesHeader{
    /* margin: 0 25% 0 0; */
    font-size: 17px;
  }
  .coursesTeach {
    font-size: 18px;
}
  .courseCards1Img{
    height: 34vh;
    width: 35vh;
  }
  .courseCards1Img img
  {
    width: 76vw;
    height: 28vh;
    margin-top: 10%;
  }
  .coursesDuration{
    margin: 1%;
    width: 65vw;
  }
  .courseCards1Content{
    width: 90%;
    margin-left: 0%;
  }
  .courseCardsDes{
    font-size: 16px;
    margin-left: 2%;
    margin-bottom: 5%;
  }
  .courseCards1 ul{
    font-size: 15px;
  }
  .coursesCards
  {
    width: 85%;
    height: 17vh;
  }
  .cards {
    width: 32%;
  }
  .cards h3 {
    font-size: 9px;
}
.cards p {
  font-size: 10px;
}
}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  .courseCards1 {
    flex-direction: column;
  }
  .courseTitle{
    font-size: 30px;
  }
  .coursesHeader{
    font-size: 13px;
  }
  .courseCards1Img{
    height: 34vh;
    width: 35vh;
  }
  .courseCards1Img img
  {
    width: 66vw;
    height: 28vh;
    margin-top: 10%;
  }
  .coursesDuration{
    margin: 1%;
    width: 67vw;
  }
  .courseCards1Content{
    width: 90%;
    margin-left: 0%;
  }
  .courseCardsDes{
    font-size: 11px;
    margin-left: 2%;
    margin-bottom: 5%;
  }
  .coursesTeach {
    font-size: 12px;
}
  .courseCards1 ul{
    font-size: 10px;
  }
  .coursesCards
  {
    width: 80%;
    margin-top: -6%;
  }
  .cards {
    width: 29%;
  }
  .cards h3 {
    font-size: 7px;
}
.cards p {
  font-size: 7px;
}
}
/*.......................................................*/

/*...........................PlacementsScreen............................*/

.countryCard {
  display: grid;
  grid-template-columns: repeat(4, minmax(135px, 1fr));
  grid-gap: 0px;
  align-items: stretch
}
.card {
  text-align: center;
  /* background-color: #f1f1f1; */
}
.card img 
{
  height: 25vh;
  width: 20vw;
}

.PlacementsScreen
{
  height: auto;
  width: 100%;
  background-color: white;
  flex-direction: column;
  padding-top: 1%;
}

.placementImg
{
  width: 10vw;
  height: 15vh;
  margin: 15%;
  object-fit: contain;
}

.placementTitle
{
  font-size: 20px;
  width: 60%;
  margin-left: 17%;
  font-family: serif;
  font-weight: bold;
}

.placementSubTitle{
  margin-top: 1%;
  /* color: white; */
  font-family: serif;
  font-style: italic;
}

#NationalPacement_Img
{
  display: block;
  width:88%;
  /* height: 25vh; */
  height: auto;
  padding: 0em 5em;
  display: block;
}

.slide-track {
  width: 100%;
  display: flex;
  gap: 8em;
  overflow: hidden;
}

.slide-track img{
  width: 100px;
  height: 100px;
  animation: scroll 20s linear infinite;
}

@keyframes scroll 
{
  0% {transform: translateX(0);}
  100% {transform: translatex(-1000%)}
}

#InternationalPacement_Img
{
  display: block;
  width:88%;
  /* height: 25vh; */
  height: auto;
  margin-top: 30px;
  padding: 0em 5em;
  display: block;
}

.slide-track-1 {
  width: 100%;
  display: flex;
  gap: 8em;
  overflow: hidden;
}

.slide-track-1 img{
  width: 100px;
  height: 100px;
  animation: scroll 20s linear infinite;
}

@media screen and (min-width: 800px) and (max-width: 1000px)
{
  #PlacementsScreen{
    height: auto;
  }
  #NationalPacement_Img{
    /* height: 18vh; */
    height: auto;
  }
  .card img {
    height: 20vh;
  }
}

@media screen and (min-width: 700px) and (max-width: 800px)
{
  #PlacementsScreen{
    height: auto;
  }
  #NationalPacement_Img{
    /* height: 18vh; */
    height: auto;
  }
  .card img {
    height: 20vh;
  }
}

@media screen and (min-width: 500px) and (max-width: 700px)
{
  .slide-track {
    flex-direction: row;
  }
  .slide-track-1 {
    flex-direction: row;
  }
  .placementTitle{
    font-size: 20px;
    width: 85%;
    margin-left: 5%;
  } 
  .countryCard{
    grid-template-columns: repeat(2, minmax(135px, 1fr));
  }
  .card img {
    width: 45vw;
}
}

@media screen and (min-width: 400px) and (max-width: 500px)
{
  .placementTitle{
    font-size: 21px;
    width: 80%;
    margin-left: 7%;
  } 
  .placementSubTitle{
    width: 75vw;
  }
  #NationalPacement_Img{
    padding: 1em 2em;
  }
  #InternationalPacement_Img{
    padding: 1em 2em;
  }
  .slide-track{
    gap: 6em;
    flex-direction: row;
  }
  .slide-track-1{
    gap: 6em;
    flex-direction: row;
  }
  .countryCard{
    grid-template-columns: repeat(2, minmax(135px, 1fr));
  }
  .card img {
    width: 45vw;
}
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  .placementTitle{
    font-size: 20px;
    width: 80%;
    margin-left: 7%;
  } 
  .placementSubTitle{
    width: 75vw;
  }
  #NationalPacement_Img{
    padding: 1em 2em;
  }
  #InternationalPacement_Img{
    padding: 1em 2em;
  }
  .slide-track{
    gap: 6em;
    flex-direction: row;
  }
  .slide-track-1{
    gap: 6em;
    flex-direction: row;
  }
  .countryCard{
    grid-template-columns: repeat(2, minmax(135px, 1fr));
  }
  .card img {
    width: 45vw;
}
}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  .placementTitle{
    font-size: 15px;
    width: 80%;
    margin-left: 10%;
  } 
  .placementSubTitle{
    width: 75vw;
    font-size: larger;
  }
  #NationalPacement_Img{
    padding: 1em 2em;
  }
  #InternationalPacement_Img{
    padding: 1em 2em;
  }
  .slide-track{
    gap: 6em;
    flex-direction: row;
  }
  .slide-track-1{
    gap: 6em;
    flex-direction: row;
  }
  .countryCard{
    grid-template-columns: repeat(2, minmax(135px, 1fr));
  }
  .card img {
    width: 45vw;
}
}
/*................................................*/

/*............serviceCard.............*/

.ServicesScreen
{
  height: auto;
  width: 100%;
  background-color:white;
  color: whitesmoke;
  flex-direction: column;
  padding-top: 1%;
}

.serviceCard
{
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
  margin-bottom: 5%;
}

.serviceTitle
{
  color: rgb(22, 22, 75);
  font-family: serif;
}

.serviceHeader
{
  text-align: center;
  font-size: 30px;
  margin: 0;
  font-style: initial;
  font-family: serif;
  font-weight: bolder;
  color: white;
}

.serviceCard h2
{
  color: whitesmoke;
  font-size: xxx-large;
}

.Eventservices1
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/Event.jpg");
  background-size: cover;
}

.Eventservices2
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/outsourcing_new.jpeg");
  background-size: cover;
}

.Eventservices3
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/Molecular.jpg");
  background-size: cover;
}

.Eventservices4
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/launchpgm.jpg");
  background-size: cover;
}

.Eventservices5
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/Outsourcing.jpg");
  background-size: cover;
}

.Eventservices6
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/Corporate_Events.jpg");
  background-size: cover;
}

.Eventservices7
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/liquor.jpg");
  background-size: cover;
}

.Eventservices8
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/EventsInter.jpg");
  background-size: cover;
}

.Eventservices9
{
  box-shadow: 2px 2px 5px 2px #888888;
  width: 95%;
  height: 45vh;
  margin: 1% 1% 1% 2%;
  border-radius: 10px;
  background-image: url("../src/Assets/Services/abroad6.jpg");
  background-size: cover;
}

.Eventservices h2
{
  color: #272727;
}

.serviceCard ul{
  color: white;
}

.serviceCard li {
  padding: 10px 2px;
}

@media screen and (min-width: 900px) and (max-width: 1000px)
{
  .serviceCard{
    grid-template-columns: repeat(1, minmax(280px, 1fr));
  }
}

@media screen and (min-width: 600px) and (max-width: 900px)
{
  .serviceCard{
    grid-template-columns: repeat(1, minmax(280px, 1fr));
  }
}

@media screen and (min-width: 500px) and (max-width: 600px)
{
  .serviceCard{
    grid-template-columns: repeat(1, minmax(280px, 1fr));
  }
  .serviceTitle {
    font-size: 20px;
  }
  .serviceCard h2
{
  font-size: x-large ;
}
}

@media screen and (min-width: 400px) and (max-width: 500px)
{
  .serviceCard{
    grid-template-columns: repeat(1, minmax(280px, 1fr));
  }
  .serviceTitle {
    font-size: 20px;
  }
  .serviceCard h2
{
  font-size: x-large;
}
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  .serviceCard{
    grid-template-columns: repeat(1, minmax(280px, 1fr));
  }
  .serviceTitle {
    font-size: x-large;
  }
  .serviceCard h2
{
  font-size: x-large;
}
}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  .serviceTitle {
    font-size: x-large;
  }
  .serviceCard h2
{
  font-size: x-large;
}
}

/*.................................................*/

/* ---------------- ContactContent start CSS ----------------*/
#ContactScreen{
  height: auto;
  background-color: black;
  color: white;
}

#ContactScreen h3 {
  margin: 0;
}

.contactScreenDiv{
  display: flex;
  width: 100%;
  background-color: black;
  color: white;
  height: 100%;
 
}

.detailsSection{
  width: 40%;
  margin: 0% 1% 1% 7%;
  border-radius: 15px;
  display: block;
  font-size: 18px;
}

.followus
{
  width: 30%;
  margin: 1% 1% 1% 5%;
  border-radius: 15px;
  display: block;
  color: white;
}

.followusIcons p{
  margin-left: 10%;
}

.followusIcons img{
  width: 10%;
  height: 6vh;
  margin: 1% 10% 1% 5%;
}

.detailsSection h1{
  font-size: 30px;
  text-align: start;
}

.detailsSection h3{
  text-align: left;
  margin: 0 0%;
  text-align: center;
}

 .companyDetails{
  color: black;
  flex-direction: column;
  margin: 10% 12% 10% 12%;
  padding: 1em 1em;
  text-align: left;
  font-size: 20px;
}

.companyAddress .mapIcon{
  padding-right: 3%;
  padding-top: 2%;
  font-size: 20px;
}

.companyAddress{
  margin: 1% 1% 1% 1%;
  position: relative;
  left: 0%;
  display: flex;
  width: 56%;
}

.contactAddress .emailIcon{
  padding-top: 2%;
}

.contactAddress a{
  padding: 1%;
  font-size: 20px;
  text-decoration: none;
}

.contactNumber{
  position: relative;
  left: 1%;
}

.contactNumber .contactIcon{
  transform: rotate(15deg);
  font-size: 20px;
}
 
.embeddedMap{
  width: 80%;
  margin: 1% 1% 1% 1%;
  border-radius: 15px;
  background-color: white;
  flex-direction: row;
  height: 55%;
}

.ubaMap{
  width: 100%;
  border-radius: 15px;
  border: white;
  height: 50vh;
  border: 1px solid;
}

.locationAddress
{
  text-align: start;
}

.location_Email
{
  width: 80%;
  text-align: start;
}

.location_Phone
{
  width: 80%;
  text-align: start;
}

.locationIcon
{
  float: left;
  margin: 0;
}

.locationAdr
{
  margin-left: 4%;
}

.locationEmail
{
  margin-left: 6%;
}

@media screen and (min-width: 1000px) and (max-width: 1100px)
{
  .detailsSection 
  {
    width: 100%;
    margin: 0% 1% 1% 2%; 
    font-size: 17px;
  }
  .embeddedMap{
    height: 40vh;
    width: 90%;
    margin: 2% 1% 1% 2%;
    }
    .ubaMap{
      height: 40vh;
    }
  .detailsSection h1
  {
    text-align: center;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px)
{
  .detailsSection 
  {
    width: 100%;
    margin: 0% 1% 1% 2%; 
  }
  .contactScreenDiv
  {
    flex-direction: column;
    width: 98%;
  }
  .embeddedMap{
    height: 40vh;
    width: 70%;
    margin: 2% 1% 1% 2%;
    }
    .ubaMap{
      height: 40vh;
    }
    .detailsSection h1
    {
      text-align: center;
    }
}

@media screen and (min-width: 800px) and (max-width: 900px)
{
  .detailsSection 
  {
    width: 100%;
    margin: 0% 1% 1% 2%; 
    font-size: 16px;
  } 
  .contactScreenDiv
  {
    flex-direction: column;
  }
  .embeddedMap{
    height: 40vh;
    width: 90%;
    margin: 2% 1% 1% 2%;
    }
    .ubaMap{
      height: 40vh;
    }
    .detailsSection h1
    {
      text-align: center;
    }
}

@media screen and (min-width: 700px) and (max-width: 800px)
{
  .detailsSection 
  {
    width: 100%;
    margin: 0% 1% 1% 2%; 
    font-size: 18px;
  }

  .contactScreenDiv
  {
    flex-direction: column;
  }
  .embeddedMap{
    height: 40vh;
    width: 90%;
    margin: 2% 1% 1% 2%;
    }
    .ubaMap{
      height: 40vh;
    }
    .detailsSection h1
    {
      text-align: center;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px)
{
  .detailsSection 
  {
    width: 100%;
    margin: 0% 1% 1% 2%; 
    font-size: 18px;
  }
  .contactScreenDiv
  {
    flex-direction: column;
  }
  .embeddedMap{
    height: 40vh;
    width: 90%;
    margin: 2% 1% 1% 2%;
    }
    .ubaMap{
      height: 40vh;
    }
    .detailsSection h1
    {
      text-align: center;
    }
}

@media screen and (min-width: 500px) and (max-width: 600px)
{
  #ContactScreen{
    height: auto;  
  }
  .contactScreenDiv
  {
    flex-direction: column;
  }
  .detailsSection{
    width: 90%;
    margin: 0% 1% 1% 2%;
    font-size: 18px;
  }
  .embeddedMap{
  height: 40vh;
  width: 90%;
  margin: 2% 1% 1% 2%;
  }
  .ubaMap{
    height: 40vh;
  }
  .detailsSection h1
  {
    text-align: center;
    font-size: larger;

  }
}

@media screen and (min-width: 400px) and (max-width: 500px)
{
  #ContactScreen{
    height: auto;  
  }

  #ContactScreen h1
  {
    font-size: larger;
  }
 

  .contactScreenDiv
  {
    flex-direction: column;
  }
  .detailsSection{
    width: 90%;
    margin: 0% 1% 1% 2%;
    font-size: 16px;
  }
  .embeddedMap{
  height: 25vh;
  width: 90%;
  margin: 2% 1% 1% 2%;
  }

  .ubaMap{
    height: 25vh;
  }
  .detailsSection h1
  {
    text-align: center;
    font-size: larger;
  }

  .locationAddress{
    font-size: 12px;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  #ContactScreen{
    height: auto;  
  }
  .contactScreenDiv
  {
    flex-direction: column;
  }
  #ContactScreen h1
  {
    font-size: larger;
  }
  .detailsSection h1
  {
    text-align: center;
  }
  .detailsSection{
    width: 90%;
    margin: 0% 1% 1% 2%;
    font-size: 14px;
  }
  .embeddedMap{
  height: 30vh;
  width: 90%;
  margin: 2% 1% 1% 2%;
  }
  
  .ubaMap{
    height: 30vh;
  }
  .detailsSection h1
  {
    text-align: center;
    font-size: larger;
  }
  .locationAddress
  {
    font-size: 12px;
  }

}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  #ContactScreen{
    height: auto;  
  }
  .contactScreenDiv
  {
    flex-direction: column;
  }
  #ContactScreen h1
  {
    font-size: larger;
  }
  .detailsSection{
    width: 95%;
    margin: 0% 1% 1% 2%;
    font-size: 11px;
  }
  .embeddedMap{
  height: 30vh;
  width: 90%;
  margin: 2% 1% 1% 2%;
  }
  
  .ubaMap{
    height: 30vh;
  }
  .detailsSection h1
  {
    text-align: center;
  }
  .location_Phone 
  {
    font-size: 13px;
}
.detailsSection h1 {
  text-align: center;
  font-size: larger;
}
}

/* ---------------- .................... ----------------*/

/*........................UB Header.............................*/

.header img
{
  width: 8%;
  height: 9vh;
  margin-left: 6%;
  margin-top: 5px;
  object-fit: contain;
  cursor: pointer;
}

.menuListHeader
{
  list-style-type: none;
  margin-left: 20%;
  line-height: 2;
  width: 55%;
  display: flex;
  padding: 0;
  font-weight: bold;
  font-family: serif;
  font-style: normal;
  font-size: 20px;
  float: left;
  color: rgb(22, 22, 75);
  cursor: pointer;
}

.menuListHeader li:hover
{
  color: rgb(255 221 0);
}


/******************* ImageSlider ****************************/

#ImageSlider
{
  background-color: black;
  height: 75vh;
  width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid rgb(0, 0, 0);
}
@media screen and (min-width: 900px) and (max-width: 1000px)
{
  #ImageSlider{
    width: 78%;
    margin-left: 10%;
    height: 35vh;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px)
{
  #ImageSlider{
    width: 78%;
    margin-left: 10%;
    height: 35vh;
  }
}

@media screen and (min-width: 700px) and (max-width: 800px)
{
  #ImageSlider{
    width: 78%;
    margin-left: 10%;
    height: 35vh;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px)
{
  #ImageSlider{
    width: 78%;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px)
{
  #ImageSlider{
    width: 78%;
  }
}

@media screen and (min-width: 400px) and (max-width: 500px)
{
  #ImageSlider{
    width: 78%;
    height: 40vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  #ImageSlider{
    width: 80%;
    height: 45vh ;
  }
}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  #ImageSlider{
    width: 80%;
    margin-left: 1%;
    height: 35vh;
  }
}


/*..................................................................*/

/*.............................Social Icons.....................................*/

.icon-bar 
{
  position: fixed;
  top: 55%;
  left: 94%;
  transform: translateY(-50%);
  z-index: 1;
}

.icon-bar a 
{
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 35px;
}

.icon-bar a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.instagram {
  background: #e24776;
  color: white;
}

.email {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.phone {
  background: rgb(22, 22, 75);
  color: white;
}



/*..................................................................*/

/*..............................Enquiry Form....................................*/

.formMainDiv{
  margin-top: 8vh;
}
.popupFormPreview 
{
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 10; */
}

.formPreview
{
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  width: 40%;
  height: 80%;
  left: 25%;
  margin-left: 30%;
  margin-top: 2%;
}

.formHeader
{
font-size: 20px;
font-weight: bold;
font-style: italic; 
margin: 1px 1px 15px 1px;
}

.form
{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  margin-left: 25%;
}

.formLabel
{
  margin: 4% 1% 4% 0%;
  text-align: initial;
  font-style: italic;
  font-weight: 700;
}

.formInput
{
  width: 100%;
  height: 4vh;
  border-radius: 10px;
}

.formButton
{
  width: 70%;
  height: 6%;
  border-radius: 10px;
  margin-top: 8%;
  margin-left: 13%;
  background: rgb(22, 22, 75);
  color: rgb(255 221 0);
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
}

.form-popup .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 40px;
}
.closebtn {
  padding: 5px 8px 5px 28px;
  text-decoration: none;
  font-size: 25px;
  color:rgb(22, 22, 75);
  display: block;
  transition: 0.3s;
  margin: 10% 4% 1% 1%;
}

.open-button {
  writing-mode: vertical-rl;
  background-color: rgb(22, 22, 75);
  color:rgb(255 221 0);
  padding: 10px 6px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
}

/* The popup form - hidden by default */
.form-popup {
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  width: 0;
  position: fixed;
  z-index: 1;
  height: 80vh;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 50px;
  color: rgb(22, 22, 75);
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width input fields */
.form-container input[type=text], .form-container input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #04AA6D;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

@media screen and (min-width: 1000px) and (max-width: 1300px)
{
  .form-popup{
    right: 1px;
    height: 88vh;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px)
{

  .icon-bar {
    left: 91%;
    top: 40%;
  }
  .form-popup{
    right: -6px;
    height: 96vh;
  }
  .formMainDiv{
    margin-top: 15vh;
  }
  .form{
    width: 70%;
    margin-left: 15%;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px)
{
  .icon-bar {
    left: 91%;
    top: 40%;
  }
  .form-popup{
    right: -6px;
    height: 94vh;
  }
  .formMainDiv{
    margin-top: 15vh;
  }
  .form{
    width: 70%;
    margin-left: 15%;
  }
}

@media screen and (min-width: 700px) and (max-width: 800px)
{
  .icon-bar {
    left: 91%;
  }
  .form-popup{
    right: -6px;
    height: 94vh;
  }
  .formMainDiv{
    margin-top: 15vh;
  }
  .form{
    width: 70%;
    margin-left: 15%;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px)
{
  .icon-bar {
    left: 90%;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px)
{
  .icon-bar {
    left: 89%;
  }
  .form-popup{
    height: 90vh;
    right: -5px;
  }
}

@media screen and (min-width: 400px) and (max-width: 500px)
{
  .icon-bar {
    left: 88%;
  }

  .form-popup{
    right: -6px;
    height: 92vh;
  }
  .formHeader{
    font-size: 19px;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  .icon-bar {
    left: 87%;
  }
  .form-popup{
    right: -6px;
    height: 90vh;
  }
  .formHeader{
    font-size: 19px;
  }
}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  .icon-bar {
    left: 82%;
  }
  .form-popup{
    right: -50px;
    height: 90vh;
  }
  .formHeader{
    font-size: 19px;
  }
  .form-popup .closebtn {
    right: 50px;
  }
  #myForm{
    width: 300px;
  }
}



/*..................................................................*/

.skillDiv{
  background-color: white;
  color: black;
  padding-top: 1%;
  width: 100%;
}

.skillDiv h1
{
  margin-bottom: 2%;
}

.skillCards{
  padding: 20px;
  border-radius: 5px;
  background: #ebe4eb;
  /* max-width: 932px; */
  margin: auto;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  /* margin-top: -10px; */
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  width: 93%;
  height: 23vh;
}

.skils{
  /* max-width: 25%;
  min-width: 25%; */
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 2px solid #e19c14
}
.skils p
{
  margin: 1% 1% 1% 10%;
}

.skils h3
{
  font-size: 14px;
  margin-bottom: 2vh;
}
.skils i
{
  margin: 1% 1% 1% 1%;
  color: rgb(22, 22, 75);
}

#branchScreen
{
  display: block;
  width: 100%;
  height: 100%;
  display: block;
  background-color: white;
  padding-top: 1px;
}

.branchTitle{
  margin-top: 1%;
  /* color: white; */
  font-family: serif;
  font-style: italic;
}

.branch-slide-track
{
  width: 100%;
  height: 50vh;
  display: flex;
  gap: 8em;
  overflow: hidden;
}

.branch-slide-track img{
  width: 100%;
  height: 50vh;
  animation: scroll 20s linear infinite;
}

@media screen and (min-width: 900px) and (max-width: 1000px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 50vw;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 50vw;
  }
  .skillCards{
    width: 90%;
    height: auto;
  }
}

@media screen and (min-width: 700px) and (max-width: 800px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 50vw;
  }
  .skillCards{
    width: 90%;
    height: auto;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 50vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 50vw;
  }
  .skillCards{
    flex-direction: column;
    height: auto;
  }
  .skils{
    width: 95%;
    border-right: 0px solid #e19c14;
    border-bottom: 1px solid #e19c14;
  }
}

@media screen and (min-width: 400px) and (max-width: 500px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 90vw;
  }
  .skillCards{
    flex-direction: column;
    height: auto;
    width: 90%;  
  }
  .skils{
    width: 95%;
    border-right: 0px solid #e19c14;
    border-bottom: 1px solid #e19c14;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 90vw;
  }
  .skillCards{
    flex-direction: column;
    height: auto;
    width: 90%;
  }
  .skils{
    width: 95%;
    border-right: 0px solid #e19c14;
    border-bottom: 1px solid #e19c14;
  }
}

@media screen and (min-width: 200px) and (max-width: 300px)
{
  .branch-slide-track
  {
  height: 40vh;
  gap: 5em;
  }
  .branch-slide-track img{
    width: 90vw;
  }
  .skillCards{
    flex-direction: column;
    height: auto;
    width: 90%;
  }
  .skils{
    width: 95%;
    border-right: 0px solid #e19c14;
    border-bottom: 1px solid #e19c14;
  }
  .skillDiv h1 {
    margin-bottom: 2%;
    font-size: x-large;
  }
} 


/* ---------------- BranchContent start CSS ----------------*/
.BranchContactScreen{
  height: 60vh;
  background-color: black;
  color: white;
}

.BranchDiv{
  float: left;
  width: 41%;
  margin-top: 1%;
  margin-left: 6%;
  text-align: left;
}

.BranchcontactScreenDiv{
  display: flex;
  width: 100%;
  background-color: white;
  color: black;
  height: 100%;
 
}

.BranchdetailsSection{
  width: 40%;
  margin: 0% 1% 1% 7%;
  border-radius: 15px;
  display: block;
}

.Branchfollowus
{
  width: 30%;
  margin: 1% 1% 1% 5%;
  border-radius: 15px;
  display: block;
  color: white;
}

.BranchfollowusIcons p{
  margin-left: 10%;
}

.BranchfollowusIcons img{
  width: 10%;
  height: 6vh;
  margin: 1% 10% 1% 5%;
}

.BranchdetailsSection h1{
  font-size: 30px;
  text-align: start;
}

.BranchdetailsSection h3{
  text-align: left;
  margin: 0 0%;
  text-align: center;
}

 .BranchcompanyDetails{
  color: black;
  flex-direction: column;
  margin: 10% 12% 10% 12%;
  padding: 1em 1em;
  text-align: left;
  font-size: 20px;
}

.BranchcompanyAddress .BranchmapIcon{
  padding-right: 3%;
  padding-top: 2%;
  font-size: 20px;
}

.BranchcompanyAddress{
  margin: 1% 1% 1% 1%;
  position: relative;
  left: 0%;
  display: flex;
  width: 56%;
}

.BranchcontactAddress .BranchemailIcon{
  padding-top: 2%;
}

.BranchcontactAddress a{
  padding: 1%;
  font-size: 20px;
  text-decoration: none;
}

.BranchcontactNumber{
  position: relative;
  left: 1%;
}

.BranchcontactNumber .BranchcontactIcon{
  transform: rotate(15deg);
  font-size: 20px;
}
 
.BranchembeddedMap{
  width: 35%;
  border-radius: 15px;
  /* background-color: white; */
  flex-direction: row;
  float: right;
  margin-right: 10%;
}

.BranchubaMap{
  width: 100%;
  border-radius: 15px;
  border: white;
  height: 45vh;
  border: 1px solid;
}

.BranchlocationAddress
{
  text-align: start;
}

.Branchlocation_Email
{
  width: 80%;
  text-align: start;
}

.Branchlocation_Phone
{
  width: 80%;
  text-align: start;
}

.BranchlocationIcon
{
  float: left;
  margin: 0;
}

.BranchlocationEmail
{
  margin-left: 6%;
}

.BranchBranchlocation_Phone{
  width: 80%;
}

@media screen and (min-width:900px) and (max-width: 1000px)
{
  /* .BranchContactScreen
  {
    height: 90vh;
  } */
  .BranchDiv {
    float: left;
    width: 94%;
  }
  .BranchlocationAddress {
    width: 74%;
  }
  .Branchlocation_Email {
    width: 50%;
  }
  .BranchBranchlocation_Phone {
    width: 50%;
  }
  .BranchembeddedMap{
    width: 75%;
    float: none;
    margin-top: 25%;
    text-align: center;
    margin-left: 6%;
  }
}

@media screen and (min-width:700px) and (max-width: 900px)
{
  /* .BranchContactScreen
  {
    height: 90vh;
  } */
  .BranchDiv {
    float: left;
    width: 94%;
  }
  .BranchlocationAddress {
    width: 74%;
  }
  .Branchlocation_Email {
    width: 50%;
  }
  .BranchBranchlocation_Phone {
    width: 50%;
  }
  .BranchembeddedMap{
    width: 75%;
    float: none;
    margin-top: 31%;
    text-align: center;
    margin-left: 6%;
  }
}

@media screen and (min-width:500px) and (max-width: 700px)
{
  /* .BranchContactScreen
  {
    height: 90vh;
  } */
  .BranchDiv {
    float: left;
    width: 94%;
  }
  .BranchDiv h3
  {
    font-size: 14px;
  }
  .BranchlocationAddress {
    width: 80%;
  }
  .Branchlocation_Email {
    width: 60%;
  }
  .BranchBranchlocation_Phone {
    width: 60%;
  }
  .BranchembeddedMap{
    width: 75%;
    float: none;
    margin-top: 25%;
    text-align: center;
    margin-left: 6%;
  }
}

@media screen and (min-width:400px) and (max-width: 500px)
{
  /* .BranchContactScreen
  {
    height: 90vh;
  } */
  .BranchDiv {
    float: left;
    width: 94%;
  }
  .BranchDiv h3
  {
    font-size: 14px;
  }
  .BranchlocationAddress {
    width: 94%;
  }
  .Branchlocation_Email {
    width: 94%;
  }
  .BranchBranchlocation_Phone {
    width: 94%;
  }
  .BranchembeddedMap{
    width: 80%;
    float: none;
    margin-top: 25%;
    text-align: center;
    margin-left: 6%;
  }
}

@media screen and (min-width:300px) and (max-width: 400px)
{
  /* .BranchContactScreen
  {
    height: 90vh;
  } */
  .BranchDiv {
    float: left;
    width: 94%;
  }
  .BranchDiv h3
  {
    font-size: 14px;
  }
  .BranchlocationAddress {
    width: 94%;
  }
  .Branchlocation_Email {
    width: 94%;
  }
  .BranchBranchlocation_Phone {
    width: 94%;
  }
  .BranchembeddedMap{
    width: 80%;
    float: none;
    margin-top: 25%;
    text-align: center;
    margin-left: 6%;
  }
}

@media screen and (min-width:200px) and (max-width: 300px)
{
  /* .BranchContactScreen
  {
    height: 90vh;
  } */
  .BranchDiv {
    float: left;
    width: 94%;
    font-size: 13px;
  }
  .BranchDiv h3
  {
    font-size: 14px;
  }
  .BranchlocationAddress {
    width: 94%;
  }
  .Branchlocation_Email {
    width: 94%;
  }
  .BranchBranchlocation_Phone {
    width: 94%;
  }
  .BranchembeddedMap{
    width: 80%;
    float: none;
    margin-top: 25%;
    text-align: center;
    margin-left: 6%;
  }
}


/*..............All Contact.........*/


.AllContactScreen{
  height: auto;
  background-color: black;
  color: white;
}
.getInTouch{
  text-align: center;
}

.AllContactSections h2{
  margin-top: 4%;
}

.contactImg{
  width: 20%;
}

.AllContactSections h4{
  margin-left: 7%;
}

.AlllocationAdr{
  margin-left: 4%;
}

.AllcontactScreenDiv{
  display: flex;
  width: 100%;
  background-color: black;
  color: white;
  height: 100%;
}

.AllContactSection{
  width: 40%;
  margin: 0% 1% 1% 7%;
  border-radius: 15px;
  display: block;
  height: 55vh;
  /* height: auto; */
}

.AllContactSection h1{
  font-size: 30px;
  text-align: start;
}

.AllContactSection h3{
  text-align: left;
  margin: 0 0%;
  text-align: center;
}

.locationName{
  font-size: 20px;
}

.AlllocationAddress
{
  text-align: start;
}

.Alllocation_Email
{
  width: 80%;
  text-align: start;
}

.Alllocation_Phone
{
  width: 80%;
  text-align: start;
}

.AlllocationIcon
{
  float: left;
  margin: 0;
}

.AlllocationEmail
{
  margin-left: 6%;
}


@media screen and (min-width:900px) and (max-width: 1000px)
{
  .AllContactSection{
    width: 85%;
    height: 75vh;
    /* height: auto; */
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 20%;
  }
}

@media screen and (min-width:800px) and (max-width: 900px)
{
  .AllContactSection{
    width: 85%;
    height: 72vh;
    /* height: auto; */
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 20%;
  }
}

@media screen and (min-width:700px) and (max-width: 800px)
{
  .AllContactSection{
    width: 85%;
    height: 70vh;
    margin: 0;
    /* height: auto; */
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 20%;
  }
}

@media screen and (min-width:600px) and (max-width: 700px)
{
  .AllContactSection{
    width: 85%;
    height: 60vh;
    /* height: auto; */
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 20%;
  }
}

@media screen and (min-width:500px) and (max-width: 600px)
{
  .AllContactSection{
    width: 85%;
    height: 60vh;
    /* height: auto; */
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 2%;
  }
  .AllContactSections h4 {
    margin-left: 0%;
}
.AllContactSections h2{
  font-size: 16px;
}
.AlllocationEmail
{
  margin-left: 11%;
}
.Alllocation_Phone 
{
  width: 90%;
}
}

@media screen and (min-width:400px) and (max-width: 500px)
{
  .AllContactSection{
    width: 85%;
    height: 55vh;
    /* height: auto; */
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 2%;
  }
  .AllContactSections h4 {
    margin-left: 0%;
}
.AllContactSections h2{
  font-size: 16px;
}
.AlllocationEmail
{
  margin-left: 11%;
}
.Alllocation_Phone 
{
  width: 85%;
}
}

@media screen and (min-width:300px) and (max-width: 400px)
{
  .AllContactSection{
    width: 85%;
    height: 65vh;
    /* height: auto; */
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 2%;
  }
  .AllContactSections h4 {
    margin-left: 0%;
}
.AllContactSections h2{
  font-size: 16px;
}
.AlllocationEmail
{
  margin-left: 11%;
}
.Alllocation_Phone 
{
  width: 90%;
}
}

@media screen and (min-width:200px) and (max-width: 300px)
{
  .AllContactSection{
    width: 85%;
    height: auto;
  }
  .AllcontactScreenDiv
  {
    flex-direction: column;
    text-align: center;
  }
  .AlllocationAddress{
    margin-left: 2%;
  }
  .AllContactSections h4 {
    margin-left: 0%;
}
.AllContactSections h2{
  font-size: 16px;
}
.AllContactSection h3{
  font-size: 16PX;
}
.AlllocationEmail
{
  margin-left: 13%;
  font-size: 13px;
}
.Alllocation_Phone 
{
  width: 100%;
  font-size: 13px;
}
.AlllocationAdr {
font-size: 13PX;
}
}

