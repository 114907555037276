
header {
  height: 75px;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%), 0 3px 8px 0 rgb(0 0 0 / 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-weight: bold;
  }

/*........................UB Header.............................*/

header img
{
  width: 8%;
  height: 9vh;
  margin-top: 5px;
  object-fit: contain;
  cursor: pointer;
}

.universalHeader{
  float: right;
  color: rgb(22, 22, 75);
  width: 7%;
  cursor: pointer;
  font-weight: bold;
}

.bartendingHeader
{
  color: rgb(255 221 0);
  /* margin-left: 10px; */
  cursor: pointer;
  margin-left: 10px;
  font-weight: bold;
}

.academyHeader{
  color: rgb(22, 22, 75);
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
}  
  .nav-area {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .logo {
    text-decoration: none;
    font-size: 25px;
    color: inherit;
    margin-left: 5%;
  }
  
  .menus {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 1px;
    list-style: none;
    text-align: center;
    width:60vw;
    justify-content: end;
  }
  
  .menu-items {
    position: relative;
    color: rgb(22, 22, 75);
    text-decoration: none;
    font-weight: bold;
  }
  
  .menu-items a {
    display: block;
    font-size: 16px;
    color: inherit;
    text-decoration: none;
    font-weight: bold;
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu-items > a, .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
    font-size: 18px;
  }
  
  .menu-items a:hover,
  .menu-items button:hover {
    background-color: rgb(255 221 0);
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  
  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

/* package css */

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
  }
  
  
  .bm-menu-wrap div{
    overflow: hidden;
  }
  
  .bm-menu {
    background: rgb(22, 22, 75);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  .bm-menu ::-webkit-scrollbar
  {
    display: none;
  }
  
  .bm-morph-shape {
    fill: #373a47;
  }
  
  .bm-item-list {
    color: #b8b7ad;
    height: 97%;
  }
  
  .bm-item {
    display: inline-block;
  }
  
  .bm-overlay {
    background: rgb(22, 22, 75);
  }
  
  #HamburgerBar{
    display: none
  }


  /*.................. header MC .........................*/

@media screen and (max-width: 1300px) and (min-width:1000px)
{
  header img{
    margin-left: 1%;
    height: 9vh;
  }
  .logo {
    margin-left: 2%;
  }
  .menu-items > a, .menu-items button
  {
    padding: 0.5rem 0.5rem;
  }

  .menus
  {
    width: 62vw;
  }
  
  .universalHeader{
    width: 8%;
    font-size: 14px;
  }
  .bartendingHeader{
    font-size: 14px;
    margin-left: 10px;
  }
  .academyHeader{
    font-size: 14px;
  }
} 

@media screen and (max-width: 1000px) and (min-width:900px)
{
  header img{
    width: 6%;
  }
  .logo {
    margin-left: 1%;
  }
  .menu-items > a, .menu-items button
  {
    padding: 0.4rem 0.4rem;
    font-size: 16px;
  }
  .menus
  {
    width: 63vw;
  }
  .universalHeader{
    width: 8%;
    font-size: 13px;
  }
  .bartendingHeader{
    font-size: 13px;
    margin-left: 10px;
  }
  .academyHeader{
    font-size: 13px;
  }
}

@media screen and (max-width: 900px) and (min-width:800px)
{
  header img{
    width: 4%;
  }
  .logo {
    margin-left: 1%;
  }
  .menu-items > a, .menu-items button
  {
    padding: 0.4rem 0.4rem;
    font-size: 13px;
    font-weight: bold;
  }
  .menus
  {
    width: 66vw;
  }
  .universalHeader{
    width: 8%;
    font-size: 12px;
  }
  .bartendingHeader{
    font-size: 12px;
    margin-left: 10px;
  }
  .academyHeader{
    font-size: 12px;
  }
}

@media screen and (max-width: 800px)
{
  .menus
  {
    display: none;
    width: 34vw;
  }
  .menu-items{
    color: white;
  }
  .universalHeader{
    width: 20%;
  }
  /* .menu-items:hover{
    color: rgb(45, 18, 97);
  } */
  .menu-items a{
    color: inherit;
  }
  .dropdown.show {
    background-color:black;
    width: 30vw;
    margin-right: 46%;
    left: 0;
  }
  #HamburgerBar{
    display: block;
    width: 60%;
  }

  #topNav{
    display: none;
  }

  .bm-burger-button{
    position: absolute;
    top: 23px;
    right: 12px;
  }

  .headerLogos img{
        height: 35%;
    margin-top: 25px;
  }
}

@media screen and (max-width: 600px) and (min-width:500px)
{
  .universalHeader{
    width: 15%;
    font-size: 14px;
  }
  .bartendingHeader{
    font-size: 14px;
    margin-left: 10px;
  }
  .academyHeader{
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) and (min-width:400px)
{
  .universalHeader{
    width: 20%;
    font-size: 14px;
    margin-left: 3px;
  }
  .bartendingHeader{
    font-size: 14px;
    margin-left: 10px;
  }
  .academyHeader{
    font-size: 14px;
  }
  header img
  {
    margin-top: 0;
  }
}

@media screen and (max-width: 400px) and (min-width:300px)
{
  .universalHeader{
    width: 21%;
    font-size: 13px;
    margin-left: 3px;
  }
  .bartendingHeader{
    font-size: 13px;
    margin-left: 10px;
  }
  .academyHeader{
    font-size: 13px;
  }
  header img
  {
    margin-top: 0;
  }
}

@media screen and (max-width: 300px) and (min-width:200px)
{
  .universalHeader{
    width: 20%;
    font-size: 10px;
    margin-left: 3px;
  }
  .bartendingHeader{
    font-size: 10px;
    margin-left: 10px;
  }
  .academyHeader{
    font-size: 10px;
  }
  header img
  {
    margin-top: 0;
  }
}

#HamburgerBar nav{
    flex-direction: column;
}

#HamburgerBar nav span:hover{
    color: blue;
}
  
  @media screen and (max-width:300px) {
    .headerLogos img{
      margin-top: 29px;
      height: 27%;
    }
  }
  
  #close{
    color: orange;
    font-size: x-large;
  }
  
  
/*......................Heade and Burger menu end .......................*/
  